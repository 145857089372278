import { Box, Stack } from "@mui/material";
import { useState } from "react";
import {
  ComponentLabelHeader,
  Text14pxMedium,
  Text16px,
  Text20pxBold,
} from "../Shared/CommonComponents";
import { LeadAssessmentHeaderText } from "../../constants/HtmlConstText";
import InputTextField from "../Shared/InputTextField";
import { AppConstants } from "../../constants/AppConstants";
import DragAndDropFileUploaderComp from "../Shared/DragAndDropFileUploader";
import {
  CircularCrossIcon,
  LeadAssessmentFileUploadIcon,
} from "../../assets/svgs";
import { uploadLeadAssessmentFile } from "./leadAssessmentServices";
import { validationEmail } from "../../utils/validations";
import TrestleLoaderButton from "../Shared/TrestleLoadingButton";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";

const LeadAssessmentForm = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({ email: "" });
  const [formError, setFormError] = useState<any>({ email: "" });
  const [isOver, setIsOver] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploadSuccess, setFileUploadSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const OnDataChange = (event: any) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!handleValidation()) {
      setIsLoading(false);
      return;
    }
    const payload = new FormData();
    payload.append("email", formData.email);
    payload.append("file", files[0]);

    await uploadLeadAssessmentFile(payload)
      .then((response) => {
        if (response.status === 200) {
          setFileUploadSuccess(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const errorData = error.response?.data || {};
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: errorData.message,
          })
        );
        setFileUploadSuccess(false);
        setIsLoading(false);
      });
  };

  const handleValidation = () => {
    const { email = "" } = formData || {};
    let isError: boolean = true;

    let tempError = {
      email: validationEmail(email),
    };

    if (tempError.email) {
      isError = false;
    }

    setFormError({ ...tempError });
    return isError;
  };

  return (
    <Stack>
      <Stack
        style={{ background: "#F5F5F5", padding: "32px 48px", width: "full" }}
        spacing={"12px"}
      >
        <ComponentLabelHeader>Contact Data Assessment</ComponentLabelHeader>
        <Text16px>
          <LeadAssessmentHeaderText />
        </Text16px>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "center", py: "65px" }}>
        <Stack
          width={"600px"}
          spacing={3}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          {!fileUploadSuccess ? (
            <>
              <InputTextField
                name={"email"}
                label={"Email"}
                value={formData}
                error={formError}
                type={"text"}
                onChange={OnDataChange}
                required={true}
                maxLength={AppConstants.MAX_EMAIL_LENGTH}
                className="!h-[48px]"
              />
              <Stack spacing={1} width={"100%"}>
                <Text14pxMedium>CSV File</Text14pxMedium>
                {files.length > 0 ? (
                  <Box className="flex justify-between items-center px-[20px] py-[10px] bg-[#F5F5F5]">
                    <Text16px>{files[0].name}</Text16px>
                    <Box
                      onClick={() => setFiles([])}
                      className="cursor-pointer"
                    >
                      {CircularCrossIcon}
                    </Box>
                  </Box>
                ) : (
                  <DragAndDropFileUploaderComp
                    setIsOver={setIsOver}
                    setFiles={setFiles}
                  />
                )}
              </Stack>
              <TrestleLoaderButton
                onClick={handleSubmit}
                loading={isLoading}
                disabled={formData.email === "" || files.length === 0}
                label="Submit"
                type="primary"
                className="!h-[50px] !p-0 !min-w-[270px] !max-w-[270px] !mt-[48px]"
              />
            </>
          ) : (
            <>
              {LeadAssessmentFileUploadIcon}
              <Text20pxBold c={"#163545"} className="text-center">
                Success! You will receive an email with a link to your data
                assessment in a few minutes.
              </Text20pxBold>
              <Text16px
                c={"#163545"}
                className="text-center flex justify-center gap-1"
              >
                A sample assessment similar to what you’ll receive can be viewed
                <ActionButtonContainer style={{ textDecoration: "underline" }}>
                  here.
                </ActionButtonContainer>
              </Text16px>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default LeadAssessmentForm;

import { useState } from "react";
import InputTextField from "../Shared/InputTextField";
import { AppConstants } from "../../constants/AppConstants";
import { Grid } from "@mui/material";
import { ConstantTexts } from "../../constants/ConstantTexts";
import TrestleButton from "../Shared/TrestleButton";
import Auth from "@aws-amplify/auth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { validatePassword } from "../../utils/validations";

const ChangePassword = ({ closePopUp, setIsLoading }: any) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<any>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formError, setFormError] = useState<{}>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const onPasswordChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const checkEqualPassword = () => {
    const { newPassword = "", confirmPassword = "" } = formData || {};
    if (newPassword === confirmPassword) {
      return "";
    } else {
      return AppConstants.CONFIRM_PASSWORD_NOT_MATCHED;
    }
  };

  const handleValidation = () => {
    const {
      oldPassword = "",
      newPassword = "",
      confirmPassword = "",
    } = formData || {};

    let tempError = {
      oldPassword: validatePassword(oldPassword),
      newPassword: validatePassword(newPassword),
      confirmPassword:
        validatePassword(confirmPassword) || checkEqualPassword(),
    };
    let isError: boolean = true;

    if (
      tempError.oldPassword ||
      tempError.newPassword ||
      tempError.confirmPassword
    ) {
      isError = false;
      setIsLoading(false);
    }

    setFormError({ ...tempError });
    return isError;
  };

  const ChangeYourPassword = async () => {
    const { oldPassword, confirmPassword } = formData;

    setIsLoading(true);
    try {
      if (handleValidation()) {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            Auth.changePassword(user, oldPassword, confirmPassword)
              .then((data) => {
                dispatch(
                  setToast({
                    open: true,
                    severity: "success",
                    msg: AppConstants.PASSWORD_CHANGE_SUCCESS,
                  })
                );
                closePopUp();
                setIsLoading(false);
              })
              .catch((error: any) => {
                setIsLoading(false);
                dispatch(
                  setToast({
                    open: true,
                    severity: AppConstants.TOAST_ERROR,
                    msg: error.message,
                  })
                );
                console.log("error: ", error.message);
              });
          })
          .catch((error: any) => {
            setIsLoading(false);
            dispatch(
              setToast({
                open: true,
                severity: AppConstants.TOAST_ERROR,
                msg: error.message,
              })
            );
            console.log("error: ", error.message);
          });
      }
    } catch (error: any) {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: error.message,
        })
      );
      console.log("error: ", error.message);
    }
  };

  return (
    <>
      <div className="w-full py-[50px] px-10 bg-[#F3F5F6] text-[#163545]">
        <div className="text-lg font-bold">Change Password</div>
        <div className="text-base font-normal mt-2">
          Change your password with your old password.
        </div>
      </div>
      <div className="p-10">
        <Grid container>
          <Grid item md={12}>
            <InputTextField
              name={"oldPassword"}
              label={"Old Password"}
              value={formData}
              error={formError}
              type={"password"}
              onChange={onPasswordChange}
              required={true}
              maxLength={AppConstants.MAX_PASSWORD_LENGTH}
            />
          </Grid>
          <Grid item md={12} className="!mt-8">
            <InputTextField
              name={"newPassword"}
              label={"New Password"}
              value={formData}
              error={formError}
              type={"password"}
              onChange={onPasswordChange}
              required={true}
              maxLength={AppConstants.MAX_PASSWORD_LENGTH}
              info={ConstantTexts.PASSWORD_POLICY_INFO}
            />
          </Grid>
          <Grid item md={12} className="!mt-8 !mb-10">
            <InputTextField
              name={"confirmPassword"}
              label={"Confirm Password"}
              value={formData}
              error={formError}
              type={"password"}
              onChange={onPasswordChange}
              required={true}
              maxLength={AppConstants.MAX_PASSWORD_LENGTH}
            />
          </Grid>
        </Grid>

        <div className="flex">
          <TrestleButton
            onClick={() => {
              closePopUp();
            }}
            label="Cancel"
            type="secondry"
            className="!w-[200px] mr-4 !h-[40px] !py-0 !pt-0 ml-auto"
          />
          <TrestleButton
            onClick={() => {
              setIsLoading(true);
              ChangeYourPassword();
            }}
            label="Update Password"
            type="primary"
            className="!w-[200px] !h-[40px] !py-0 !pt-0"
          />
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

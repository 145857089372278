import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { BrandingPage } from "../../Shared/BrandingPage";
import {
  MainHeaderText,
  BrandingPageChildContainer,
} from "../../Shared/CommonComponents";
import InputTextField from "../../Shared/InputTextField";
import { Grid } from "@mui/material";
import { CheckBoxComponent } from "../../Shared/ToggleComponents";
import TrestleButton from "../../Shared/TrestleButton";
import { ForwardArrow } from "../../../assets/svgs";
import { RouteConstants } from "../../../constants/RouteConstants";
import {
  validationEmail,
  validatePhone,
  formatName,
  formatPhone,
  validatePassword,
} from "../../../utils/validations";
import { AppConstants } from "../../../constants/AppConstants";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import DropDown from "../../Shared/DropDown";
import { signUpAPICall } from "../../../http/authentication-service";
import DialogPopup from "../../Shared/DialogPopup";
import Terms from "../Terms/terms";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { EncryptPasswordAES } from "../../../utils/passwordEncryption";
import Auth from "@aws-amplify/auth";
import LoaderComponent from "../../Shared/LoaderComponent";

const Optionaloptions = [
  "Search Engines (Google, Bing, etc.)",
  "Internet Ads",
  "LinkedIn",
  "Trestle Blog",
  "Word of Mouth",
  "Referral",
  "Third-Party Review Site (G2, CB Insights)",
  "Other",
];

export const ConfirmResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token: any = useAppSelector((state: any) => state.authReducer.token);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userEmail: string = sessionStorage.getItem("userEmail") || "";

  const [formData, setFormData] = useState<any>({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState<any>({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });

  const OnDataChange = (event: any) => {
    const { value, name } = event.target;
    let val: any = value;
    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const disabledSubmit = () => {
    const { otp = "", newPassword = "", confirmPassword = "" } = formData || {};

    return !(otp.trim() && newPassword.trim() && confirmPassword.trim());
  };

  const checkEqualPassword = () => {
    const { newPassword = "", confirmPassword = "" } = formData || {};
    if (newPassword === confirmPassword) {
      return "";
    } else {
      return AppConstants.CONFIRM_PASSWORD_NOT_MATCHED;
    }
  };

  const handleValidation = async () => {
    const { otp = "", newPassword = "", confirmPassword = "" } = formData || {};

    let tempError = {
      otp: "",
      newPassword: validatePassword(newPassword),
      confirmPassword:
        validatePassword(confirmPassword) || checkEqualPassword(),
    };
    let isError: boolean = true;

    if (tempError.otp || tempError.newPassword || tempError.confirmPassword) {
      isError = false;
      setIsLoading(false);
    }

    setFormError({ ...tempError });
    return isError;
  };

  const ResetPassworCall = async () => {
    setIsLoading(true);
    const isError: boolean = await handleValidation();

    if (isError && !disabledSubmit()) {
      try {
        const response = await Auth.forgotPasswordSubmit(
          userEmail,
          formData.otp,
          formData.newPassword
        );
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: AppConstants.PASSWORD_RESET_SUCCESS,
          })
        );
        navigate(RouteConstants.ROUTE_LOGIN);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        if (
          error.message ===
          "Invalid verification code provided, please try again."
        ) {
          setFormError({
            ...formError,
            otp: error.message,
          });
        } else {
          dispatch(
            setToast({
              open: true,
              severity: AppConstants.TOAST_ERROR,
              msg: error.message,
            })
          );
        }
        console.log("error: ", error.message);
      }
    }
  };

  return (
    <>
      {/* <LoaderComponent loader={isLoading} /> */}
      <BrandingPage loader={isLoading}>
        <BrandingPageChildContainer>
          <MainHeaderText>{ConstantTexts.RESET_PASSWORD_HEADER}</MainHeaderText>
          <div className="mt-[20px] text-base font-normal text-[#163545]">
            {ConstantTexts.CONFIRM_RESET_PASSWORD_INFO_TEXT} {userEmail}
          </div>

          <div className="mt-[60px]">
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12} className="!pt-[25px]">
                <InputTextField
                  name={"otp"}
                  label={"Verification Code"}
                  value={formData}
                  error={formError}
                  type={"number"}
                  onChange={OnDataChange}
                  required={true}
                  maxLength={AppConstants.MAX_EMAIL_LENGTH}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12} className="!pt-[25px]">
                <InputTextField
                  name={"newPassword"}
                  label={"New Password"}
                  value={formData}
                  error={formError}
                  type={"password"}
                  onChange={OnDataChange}
                  required={true}
                  maxLength={AppConstants.MAX_PASSWORD_LENGTH}
                //   info={ConstantTexts.PASSWORD_POLICY_INFO}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12} className="!pt-[25px]">
                <InputTextField
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  value={formData}
                  error={formError}
                  type={"password"}
                  onChange={OnDataChange}
                  required={true}
                  maxLength={AppConstants.MAX_PASSWORD_LENGTH}
                  info={ConstantTexts.PASSWORD_POLICY_INFO}
                />
              </Grid>
            </Grid>
          </div>

          <div className="ml-auto w-[200px] mt-[60px]">
            <TrestleButton
              onClick={() => {
                setIsLoading(true);
                ResetPassworCall();
              }}
              label="Reset Password"
              disabled={disabledSubmit()}
              type="primary"
              icon={ForwardArrow}
            />
          </div>
        </BrandingPageChildContainer>
      </BrandingPage>
    </>
  );
};

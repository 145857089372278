import { Box, Divider, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getLeadAssessmentFileReport } from "./leadAssessmentServices";
import {
  ComponentLabelHeader,
  Text14pxMedium,
  Text16px,
  Text20pxBold,
  Text20pxMedium,
} from "../Shared/CommonComponents";
import {
  CircularCrossIcon,
  CircularDashIcon,
  CircularGreenTickIcon,
  ClosePopup,
  CopyClipboardIcon,
  DownloadIcon,
  LinkedInIcon,
  MailIcon,
  ShareIcon,
  TickIcon,
} from "../../assets/svgs";
import CustomTable from "../Shared/TrestleTableNew";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";
import { RouteConstants } from "../../constants/RouteConstants";
import TrestleButton from "../Shared/TrestleButton";
import DialogPopup from "../Shared/DialogPopup";
import {
  getAddressData,
  getEmailData,
  getPhoneData,
  getSummaryData,
} from "./dataAssessmentUtils";
import TableAndGraphView from "./TableAndGraphView";

const LeadAssessmentReportPage = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [leadAssessmentData, setLeadAssessmentData] = useState<any>(null);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [phoneData, setPhoneData] = useState<any>(null);
  const [emailData, setEmailData] = useState<any>(null);
  const [addressData, setAddressData] = useState<any>(null);
  const [summary, setSummary] = useState<any>(null);
  const [phoneContactGrade, setPhoneContactGrade] = useState<any>();
  const [phoneValidData, setPhoneValidData] = useState<any>();
  const [phoneActivityScore, setPhoneActivityScore] = useState<any>();
  const [lineType, setLineType] = useState<any>();
  const [emailGrade, setEmailGrade] = useState<any>();
  const [emailIsValid, setEmailIsValid] = useState<any>();
  const [addressMatch, setAddressMatch] = useState<any>();
  const [addressIsValid, setAddressIsValid] = useState<any>();
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [copy, setIsCopy] = useState(false);

  const EmailLink = () => {
    const currentUrl = window.location.href;
    const subject = "Sharing Batch Assessment data";
    const body = `Hello,\n\nI'd like to share this real contact data assessment of how our contact data compares to Trestle's benchmarks.\n\nHere are the links:\n- Assessment Link: ${currentUrl}\n- Trestle Benchmark: https://trestleiq.com/\n\nBest regards,`;
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    return mailtoLink;
  };

  useEffect(() => {
    getLeadAssessmentReport();
  }, [uuid]);

  const getLeadAssessmentReport = async () => {
    await getLeadAssessmentFileReport(uuid)
      .then((response) => {
        setLeadAssessmentData(response.data.data);
        const aggregates = response.data.data.reportAnalysisData.aggregates;
        const metrics = response.data.data.reportAnalysisData.metrics;
        const benchmark = response.data.data.benchmarks;
        const productName = response.data.data.productName;
        try {
          setSummary(getSummaryData(response.data.data));
          setPhoneData(getPhoneData(response.data.data));
          setEmailData(getEmailData(response.data.data));
          setAddressData(getAddressData(response.data.data));
          setPhoneContactGrade({
            labels: ["Grade F", "Grade D", "Grade C", "Grade B", "Grade A"],
            datasets: [
              {
                label: "Your Data",
                data: [
                  metrics.PHONE_CONTACT_GRADE_F_PERCENTAGE,
                  metrics.PHONE_CONTACT_GRADE_D_PERCENTAGE,
                  metrics.PHONE_CONTACT_GRADE_C_PERCENTAGE,
                  metrics.PHONE_CONTACT_GRADE_B_PERCENTAGE0,
                  metrics.PHONE_CONTACT_GRADE_A_PERCENTAGE,
                ],
                backgroundColor: "#555555",
              },
              {
                label: "Trestle Benchmark",
                data: [
                  benchmark.PHONE_CONTACT_GRADE_F_PERCENTAGE,
                  benchmark.PHONE_CONTACT_GRADE_D_PERCENTAGE,
                  benchmark.PHONE_CONTACT_GRADE_C_PERCENTAGE,
                  benchmark.PHONE_CONTACT_GRADE_B_PERCENTAGE,
                  benchmark.PHONE_CONTACT_GRADE_A_PERCENTAGE,
                ],
                backgroundColor: "#0494B4",
              },
            ],
          });

          setPhoneValidData({
            labels: ["Invalid", "Valid"],
            datasets: [
              {
                label: "Your Data",
                data: [
                  metrics.INVALID_PHONE_PERCENTAGE,
                  100 - metrics.INVALID_PHONE_PERCENTAGE,
                ],
                backgroundColor: "#555555",
              },
              {
                label: "Trestle Benchmark",
                data: [
                  benchmark.INVALID_PHONE_PERCENTAGE,
                  benchmark.VALID_PHONE_PERCENTAGE,
                ],
                backgroundColor: "#0494B4",
              },
            ],
          });
          setPhoneActivityScore({
            labels: ["0-30", "31-69", "70-100"], // Labels on y-axis
            datasets: [
              {
                label: "Your Data",
                data: [
                  metrics.LOW_ACTIVITY_PERCENTAGE,
                  metrics.MEDIUM_ACTIVITY_PERCENTAGE,
                  metrics.HIGH_ACTIVITY_PERCENTAGE,
                ], // Adjust values as needed
                backgroundColor: "#555555",
              },
              {
                label: "Trestle Benchmark",
                data: [
                  benchmark.LOW_ACTIVITY_PERCENTAGE,
                  benchmark.MEDIUM_ACTIVITY_PERCENTAGE,
                  metrics.HIGH_ACTIVITY_PERCENTAGE,
                ], // Adjust values as needed
                backgroundColor: "#0494B4",
              },
            ],
          });
          setLineType({
            labels: ["Landline", "Mobile", "FixedVoIP", "NonFixedVoIP"],
            datasets: [
              {
                label: "Line Type",
                data: [
                  aggregates.LANDLINE_AND_FIXEDVOIP_LINE_TYPES,
                  aggregates.MOBILE_LINE_TYPES,
                  aggregates.LANDLINE_AND_FIXEDVOIP_LINE_TYPES,
                  aggregates.NONFIXEDVOIP_LINE_TYPES,
                ], // Adjust these values as needed
                backgroundColor: ["#0494B4", "#44DAD3", "#006D88", "#F78331"],
                hoverOffset: 5,
              },
            ],
          });
          setEmailGrade({
            labels: ["Grade F", "Grade D", "Grade C", "Grade B", "Grade A"],
            datasets: [
              {
                label: "Your Data",
                data: [
                  metrics.EMAIL_GRADE_F_PERCENTAGE,
                  metrics.EMAIL_GRADE_D_PERCENTAGE,
                  metrics.EMAIL_GRADE_C_PERCENTAGE,
                  metrics.EMAIL_GRADE_B_PERCENTAGE,
                  metrics.EMAIL_GRADE_A_PERCENTAGE,
                ],
                backgroundColor: "#555555",
              },
              {
                label: "Trestle Benchmark",
                data: [
                  benchmark.EMAIL_GRADE_F_PERCENTAGE,
                  benchmark.EMAIL_GRADE_D_PERCENTAGE,
                  benchmark.EMAIL_GRADE_C_PERCENTAGE,
                  benchmark.EMAIL_GRADE_B_PERCENTAGE,
                  benchmark.EMAIL_GRADE_A_PERCENTAGE,
                ],
                backgroundColor: "#0494B4",
              },
            ],
          });
          setEmailIsValid({
            labels: ["Valid", "Invalid"],
            datasets: [
              {
                label: "Email Validity",
                data: [
                  100 - metrics.EMAIL_INVALID_PERCENTAGE,
                  metrics.EMAIL_INVALID_PERCENTAGE,
                ], // Adjust these values as needed
                backgroundColor: ["#44DAD3", "#F78331"],
                hoverOffset: 5,
              },
            ],
          });
          setAddressMatch({
            labels: ["Match", "No Match"],
            datasets: [
              {
                label: "Your Data",
                data: [
                  metrics.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE,
                  metrics.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE,
                ],
                backgroundColor: "#555555",
              },
              {
                label: "Trestle Benchmark",
                data: [
                  benchmark.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE,
                  benchmark.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE,
                ],
                backgroundColor: "#0494B4",
              },
            ],
          });
          setAddressIsValid({
            labels: ["Valid", "Invalid"],
            datasets: [
              {
                labADDRESS_NAME_MATCH_TRUE_PERCENTAGEel: "Address Validity",
                data: [
                  100 - metrics.INVALID_ADDRESS_PERCENTAGE,
                  metrics.INVALID_ADDRESS_PERCENTAGE,
                ], // Adjust these values as needed
                backgroundColor: ["#44DAD3", "#F78331"],
                hoverOffset: 5,
              },
            ],
          });
        } catch (error) {}
      })
      .catch((error) => {});
  };

  const getRecommendationIcon = (type: string) => {
    switch (type) {
      case "POSITIVE":
        return CircularGreenTickIcon;
      case "NEUTRAL":
        return CircularDashIcon;
      case "NEGATIVE":
        return CircularCrossIcon;
    }
  };

  const convertToHyperlink = (text: string) => {
    const regex = /\((https?:\/\/[^\s)]+)\)/;
    const match = text.match(regex);
    if (match) {
      const url = match[1];
      const text1 = text.slice(0, match.index);
      const text2 = text.slice(
        match?.index ? match?.index + match[0].length : 0
      );
      try {
        return (
          <div>
            {text1}
            <a
              href={`${url}`}
              target="_blank"
              style={{ color: "#3abec8", textDecoration: "underline" }}
            >
              here
            </a>
            {text2}
          </div>
        );
      } catch {
        return text;
      }
    }
    return text;
  };

  const html2pdf = require("html2pdf.js");
  const downloadPdfCall = () => {
    const element = document.getElementById("report");
    if (element) {
      const options = {
        margin: 5,
        filename: `${leadAssessmentData?.fileName?.replace(".csv", ".pdf")}`,
        html2canvas: {
          scale: 2,
          width: element?.offsetWidth,
          useCORS: true,
          allowTaint: true,
          backgroundColor: "#F4F4F4",
        },
        jsPDF: {
          unit: "px",
          format: [element?.offsetWidth, element?.offsetHeight + 100],
          orientation: "portrait",
          precision: 4,
        },
      };

      // Trigger PDF generation
      html2pdf().from(element).set(options).save();
    }
  };

  return (
    <>
      {leadAssessmentData && (
        <Box>
          <Stack
            direction={"row"}
            sx={{
              background: "#F5F5F5",
              padding: "32px 48px",
              width: "full",
              justifyContent: "space-between",
            }}
          >
            <Stack>
              <ComponentLabelHeader>
                {leadAssessmentData?.productName === "Phone Intel 3.0"
                  ? "Phone Validation Data Assessment"
                  : "Real Contact Data Assessment"}
              </ComponentLabelHeader>
              <Text16px>{leadAssessmentData?.fileName}</Text16px>
            </Stack>

            <Stack direction={"row"} spacing={2}>
              <IconButton
                sx={{
                  color: "#FFFFFF",
                  background: "#0494B4",
                  borderRadius: "0",
                  width: "48px",
                  height: "48px",
                  marginTop: "0px",
                  "&:hover": {
                    background: "#0494B4",
                  },
                }}
                onClick={() => {
                  setOpenShareDialog(true);
                }}
              >
                {ShareIcon}
              </IconButton>
              <IconButton
                sx={{
                  color: "#FFFFFF",
                  background: "#0494B4",
                  borderRadius: "0",
                  width: "48px",
                  height: "48px",
                  marginTop: "0px",
                  "&:hover": {
                    background: "#0494B4",
                  },
                }}
                onClick={downloadPdfCall}
              >
                {DownloadIcon}
              </IconButton>
            </Stack>
          </Stack>
          <Box id={"report"}>
            <Stack
              sx={{
                background: "#FFFFFF",
                padding: "32px 48px",
                width: "full",
                justifyContent: "space-between",
                maxWidth: "1440px",
                mx: "auto",
              }}
              spacing={6}
            >
              <Stack spacing={2}>
                <ComponentLabelHeader>Recommendations</ComponentLabelHeader>
                <Stack spacing={3}>
                  <Text16px>
                    {
                      leadAssessmentData?.reportAnalysisData
                        ?.recommendationSummary
                    }
                  </Text16px>
                  <Stack spacing={2} pl={"30px"}>
                    {leadAssessmentData?.reportAnalysisData?.recommendations.map(
                      (recommendation: any, index: any) => {
                        return (
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ alignItems: "center" }}
                          >
                            <Box sx={{ width: "20px", height: "20px" }}>
                              {getRecommendationIcon(recommendation?.type)}
                            </Box>

                            <Text16px>
                              {convertToHyperlink(recommendation?.description)}
                            </Text16px>
                          </Stack>
                        );
                      }
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{ justifyContent: "space-between" }}
                >
                  <ComponentLabelHeader>Summary</ComponentLabelHeader>
                </Stack>
                <CustomTable rows={summary} />
              </Stack>

              <TableAndGraphView
                title="Phone Data"
                tableData={phoneData}
                verticalGraphData={
                  leadAssessmentData?.productName !== "Phone Intel 3.0"
                    ? phoneContactGrade
                    : phoneValidData
                }
                donutChartData={lineType}
                horizontalGraphData={phoneActivityScore}
                toggleGraph={() => setShowTable(false)}
                toggleTable={() => setShowTable(true)}
                showTable={showTable}
                verticalGraphLabel={
                  leadAssessmentData?.productName !== "Phone Intel 3.0"
                    ? "Phone Contact Grade"
                    : "Phone Validity"
                }
                horizontalGraphLabel="Phone Activity Score"
                donutChartLabel="Line Type"
              />
              {leadAssessmentData?.productName !== "Phone Intel 3.0" &&
                leadAssessmentData?.reportAnalysisData?.aggregates
                  ?.RECORDS_WITH_EMAILS !== 0 && (
                  <TableAndGraphView
                    title="Email Data"
                    tableData={emailData}
                    verticalGraphData={emailGrade}
                    donutChartData={emailIsValid}
                    toggleGraph={() => setShowTable(false)}
                    toggleTable={() => setShowTable(true)}
                    showTable={showTable}
                    verticalGraphLabel={"Email Contact Grade"}
                    donutChartLabel="Email Validity"
                  />
                )}
              {leadAssessmentData?.productName != "Phone Intel 3.0" &&
                leadAssessmentData?.reportAnalysisData?.aggregates
                  ?.RECORDS_WITH_ADDRESSES !== 0 && (
                  <TableAndGraphView
                    title="Address Data"
                    tableData={addressData}
                    verticalGraphData={addressMatch}
                    donutChartData={addressIsValid}
                    toggleGraph={() => setShowTable(false)}
                    toggleTable={() => setShowTable(true)}
                    showTable={showTable}
                    verticalGraphLabel={"Address Match Score"}
                    donutChartLabel="Address Validity"
                  />
                )}
            </Stack>
            <Box
              sx={{
                pt: "24px",
                pb: "72px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
                flexDirection: "column",
                fontSize: "24px",
              }}
            >
              Want to assess more contact data?{" "}
              <ActionButtonContainer
                style={{ textDecoration: "underline", fontSize: "24px" }}
                onClick={() =>
                  navigate(RouteConstants.ROUTE_LEAD_ASSESSMENT_FORM)
                }
              >
                Upload a New File
              </ActionButtonContainer>
            </Box>
            <Stack
              spacing={6}
              sx={{
                py: "64px",
                background: "#163545",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <ComponentLabelHeader style={{ color: "#FFFFFF" }}>
                Do More With Trestle
              </ComponentLabelHeader>
              <Stack
                direction={"row"}
                spacing={4}
                alignItems={"center"}
                width={"full"}
              >
                <Stack alignItems={"center"} width={"420px"} spacing={2}>
                  <Text20pxBold c={"#A2F0EC"}>
                    Ready to turn insights into action?
                  </Text20pxBold>
                  <Text16px c={"#FFFFFF"}>
                    Our Real Contact API goes beyond aggregated results—unlock
                    access to raw, real-time data on every contact. 
                  </Text16px>
                  <TrestleButton
                    onClick={() => navigate(RouteConstants.ROUTE_SIGNUP)}
                    type={"orange_contain"}
                    label="Sign Up for a Free Account"
                    className="!h-[48px] !min-w-[300px] !max-w-[300px]"
                  />
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "#00485A" }}
                />
                <Stack alignItems={"center"} width={"420px"} spacing={2}>
                  <Text20pxBold c={"#A2F0EC"}>
                    Need guidance on how Trestle can help?
                  </Text20pxBold>
                  <Text16px c={"#FFFFFF"}>
                    Let’s talk about how Trestle can best serve the unique needs
                    of your business.
                  </Text16px>
                  <TrestleButton
                    onClick={() =>
                      window.open("https://trestleiq.com/contact/", "_blank")
                    }
                    type={"new_contact"}
                    label="Book a Meeting With Our Team"
                    className="!h-[48px] !min-w-[300px] !max-w-[300px]"
                  />
                </Stack>
              </Stack>
            </Stack>
          </Box>

          <DialogPopup
            open={openShareDialog}
            onClose={() => {
              setOpenShareDialog(false);
            }}
            title=""
            closeButton={true}
            component={
              <Stack width={"580px"} padding={"40px"} spacing={6}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Text20pxMedium>Share This Assessment</Text20pxMedium>
                  <Box
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenShareDialog(false);
                    }}
                  >
                    {ClosePopup}
                  </Box>
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <Box component={"a"} href={EmailLink()}>
                    <IconButton
                      sx={{
                        color: "#FFFFFF",
                        background: "#0494B4",
                        borderRadius: "0",
                        width: "48px",
                        height: "48px",
                        marginTop: "0px",
                        "&:hover": {
                          background: "#0494B4",
                        },
                      }}
                      onClick={() => {}}
                    >
                      {MailIcon}
                    </IconButton>
                  </Box>

                  {/* <IconButton
                    sx={{
                      color: "#FFFFFF",
                      background: "#0494B4",
                      borderRadius: "0",
                      width: "48px",
                      height: "48px",
                      marginTop: "0px",
                      "&:hover": {
                        background: "#0494B4",
                      },
                    }}
                    onClick={() => {}}
                  >
                    {LinkedInIcon}
                  </IconButton> */}
                </Stack>

                <Stack spacing={2}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Text14pxMedium>Share Link</Text14pxMedium>
                    {copy && (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                      >
                        {TickIcon}
                        <Text14pxMedium c="#00B47E">Link Copied</Text14pxMedium>
                      </Stack>
                    )}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className="h-[50px] w-full bg-[#F5F5F5] pl-[20px]"
                  >
                    <Text16px
                      c={"#163545"}
                      className="truncate items-center justify-center"
                    >
                      {window.location.href}
                    </Text16px>
                    <Stack
                      direction={"row"}
                      px={"20px"}
                      spacing={1}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className="bg-[#5555551A] h-[50px] cursor-pointer"
                      onClick={() => {
                        setIsCopy(true);
                        navigator.clipboard.writeText(
                          window.location.href || ""
                        );
                      }}
                    >
                      <Text16px c={"#163545"} className="truncate">
                        Copy
                      </Text16px>
                      {CopyClipboardIcon}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            }
          />
        </Box>
      )}
    </>
  );
};

export default LeadAssessmentReportPage;

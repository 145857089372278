import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Text14pxMedium, WalletWarningText } from "../Shared/CommonComponents";
import styled from "@emotion/styled";
import TrestleButton from "../Shared/TrestleButton";
import { CheckBoxComponent } from "../Shared/ToggleComponents";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";
import { AppConstants } from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import {
  CreateManageSubscriptionSession,
  GetMaxioBillingPortalURL,
  GetMaxioCustomerID,
  GetWalletData,
  UpdatemonthlyTransactionLimit,
} from "../Shared/PaymentWallet/Services/PaymentWalletServices";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { HTTP_STATUS } from "../../http/constants/http.status";

interface BillingDetailsTabProps {
  setDialogOpen: any;
  setLoading: any;
  userEmail: any;
}

const BillingDetailsTab = ({
  setDialogOpen,
  setLoading,
  userEmail,
}: BillingDetailsTabProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [walletData, setWalletData] = useState<any>(null);
  const [isMaxSpendLimitEnabled, setIsMaxSpendLimitEnabled] =
    useState<boolean>(false);
  const [isCustomerIdAvailable, setIsCustomerIdAvailable] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const fetchWalletData = async () => {
      const data = await GetWalletData(userEmail.replace("+", "%2b"));
      setWalletData(data);
      setIsMaxSpendLimitEnabled(
        data.data.monthlyTransactionLimit ===
          AppConstants.MAX_MONTHLY_SPEND_LIMIT
      );
      setIsLoading(false);
    };

    fetchWalletData();
  }, []);
  const fetchMaxioCustomerId = async () => {
    const data = await GetMaxioCustomerID(userEmail.replace("+", "%2b"));
    setIsCustomerIdAvailable(data?.message === ConstantTexts.MAXIO_GET_CUSTOMER_ID_SUCCESS);
  };


  useEffect(() => {

    fetchMaxioCustomerId();
  }, []);

  const manageSubscriptionApiCall = async () => {
    setLoading(true);
    const response = await CreateManageSubscriptionSession(userEmail);
    const { statusCode = 401, data = {} } = response;
    if (statusCode === 200) {
      window.open(data, "_self");
    }
  };

  const handleMaxSpendLimitChange = () => {
    setIsMaxSpendLimitEnabled((prev) => !prev);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsMaxSpendLimitEnabled(
      walletData.data.monthlyTransactionLimit ===
        AppConstants.MAX_MONTHLY_SPEND_LIMIT
    );
    setIsEditing(false);
  };

  const handleMaxioBillingPortalRedirect = async () => {
    try {
      const data = await GetMaxioBillingPortalURL(
        userEmail.replace("+", "%2b")
      );

      // Check if there was an error in the response
      if (data.code !== HTTP_STATUS.HTTP_OK) {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
          })
        );
        return;
      }

      // If no error, get the URL from the response and redirect
      const url = data?.data?.url;
      if (url) {
        // Redirect to the URL in a new tab/window
        window.open(url, "_blank");
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
          })
        );
      }
    } catch (error) {
      // Handle any unexpected errors in the API call
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.MAXIO_BILLING_PORTAL_URL_FETCH_ERROR,
        })
      );
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const newLimit = isMaxSpendLimitEnabled
      ? AppConstants.MAX_MONTHLY_SPEND_LIMIT
      : AppConstants.DEFAULT_MONTHLY_SPEND_LIMIT;
    try {
      const response = await UpdatemonthlyTransactionLimit(
        userEmail,
        newLimit.toString()
      );
      if (response.statusCode === 200) {
        setWalletData((prevState: any) => ({
          ...prevState,
          data: {
            ...prevState.data,
            monthlyTransactionLimit: newLimit,
          },
        }));
        setIsMaxSpendLimitEnabled(
          newLimit === AppConstants.MAX_MONTHLY_SPEND_LIMIT
        );
        setIsEditing(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: ConstantTexts.SPEND_LIMIT_UPDATED,
          })
        );
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.SPEND_LIMIT_UPDATE_ERROR,
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.SPEND_LIMIT_UPDATE_ERROR,
        })
      );
    }
    setIsSaving(false);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      {walletData.data.walletDisplayType === "SELF_SERVE" ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Text14pxMedium>
                {`Wallet balance: ${
                  walletData.data.balance !== undefined &&
                  walletData.data.balance !== null
                    ? parseFloat(walletData.data.balance) < 0
                      ? `-$${Math.abs(
                          parseFloat(walletData.data.balance)
                        ).toFixed(2)}`
                      : `$${parseFloat(walletData.data.balance).toFixed(2)}`
                    : "0.00"
                }`}
              </Text14pxMedium>
              <Text14pxMedium>
                {walletData.data.autoRechargeEnabled
                  ? "Automatic wallet refill enabled"
                  : "Automatic wallet refill disabled"}
              </Text14pxMedium>
              {walletData.data.autoRechargeEnabled && (
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "15px" }}
                >
                  <Text14pxMedium>
                    Threshold: ${walletData.data.balanceThreshold}
                  </Text14pxMedium>
                  <Text14pxMedium>
                    Refill amount to: ${walletData.data.targetBalance}
                  </Text14pxMedium>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <ActionButtonContainer
                u={"underline"}
                onClick={manageSubscriptionApiCall}
              >
                Stripe Billing Details
              </ActionButtonContainer>
            </Box>
          </PlanDetailsCardContainer>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <div className="flex items-center">
              <CheckBoxComponent
                value={isMaxSpendLimitEnabled}
                toggleCheck={handleMaxSpendLimitChange}
              />
              <span className="text-[15px] font-normal text-[#163545] leading-10 ml-2 mt-auto">
                Enable maximum spending limit of $5000
              </span>
            </div>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <WalletWarningText c={"#163545"}>Plan</WalletWarningText>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Text14pxMedium>{walletData.data.walletDisplayType === 'UNLIMITED'? 'Enterprise': 'Trial' }</Text14pxMedium>
            </Box>
          </PlanDetailsCardContainer>
        </Box>
      )}
      {isEditing && !isSaving && (
        <SaveChangesBtnPopup className="w-full p-10 bg-white">
          <div className="flex">
            <TrestleButton
              onClick={handleCancel}
              label="Cancel and Revert Changes"
              type="secondry"
              className="flex-1 !max-w-[250px]"
            />
            <TrestleButton
              onClick={handleSave}
              label="Save Changes"
              type="primary"
              className="!w-[250px] ml-auto"
            />
          </div>
        </SaveChangesBtnPopup>
      )}
      {/* Conditionally render Billing Portal URL button when isCustomerIdAvailable is true */}
      {isCustomerIdAvailable && (
        <TrestleButton
          onClick={handleMaxioBillingPortalRedirect} // Ensure this handler exists
          label="Billing Portal"
          type="primary"
          className="!h-[50px] !leading-[40px] !py-[20px] !pl-[20px] !pr-[20px] !w-fit !mt-[30px]"
        />
      )}
    </>
  );
};

export const PlanDetailsCardContainer = styled.div`
  width: 100%;
  height: flex;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
`;

export const SaveChangesBtnPopup = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  margin-left: -40px;
  width: 75%;
  box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
`;

export default BillingDetailsTab;

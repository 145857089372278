import { Suspense } from "react";
import {
  Navigate,
  Route,
  Routes as RoutesReactRouterDom,
} from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { Login } from "../components/Onboarding/Login/Login";
import { SignUp } from "../components/Onboarding/SignUp/SignUp";
import { VerifyOTP } from "../components/Onboarding/VerifyOTP/VerifyOTP";
import { ResetPassword } from "../components/Onboarding/ResetPassword/ResetPassword";
import { useAppSelector } from "../app/hooks";
import { ConfirmResetPassword } from "../components/Onboarding/ResetPassword/ConfirmResetPasssword";
import { AlreadyAnAccount } from "../components/Onboarding/SignUp/AlreadyAnAccount";
import TrestleAppBar from "../components/Shared/Appbar/Appbar";
import { AdminRoutes } from "./adminRoute";
import { UserRoute } from "./userRoute";
import "./styles.css";
import { ForceChangePassword } from "../components/Onboarding/Login/ForceChangePassword";
import HomeComponent from "../components/Shared/HomeComponent";
import * as Sentry from "@sentry/react";
import PublicPagesLayout from "../components/Shared/PublicLayout";
import { LeadAssessmentRoute } from "./leadAssessmentRoute";
import LeadAssessmentForm from "../components/LeadAssessment";
import LeadAssessmentReportPage from "../components/LeadAssessment/LeadAssessmentReport";

export const Routes = () => {
  const isLoggedIn: any = useAppSelector(
    (state: any) => state.authReducer.idToken
  );

  const userMode: any = useAppSelector(
    (state: any) => state.authReducer.userMode
  );

  const SentryRoutes =
    Sentry.withSentryReactRouterV6Routing(RoutesReactRouterDom);

  return (
    <Suspense fallback={<p>Loading</p>}>
      {/* this are sample route this will get replace after an Login and sign-up implementation */}

      {isLoggedIn ? (
        <>
          <TrestleAppBar>
            {userMode &&
              (userMode === "ADMIN" ? <AdminRoutes /> : <UserRoute />)}
          </TrestleAppBar>
        </>
      ) : (
        <>
          <SentryRoutes>
            <Route path={RouteConstants.ROUTE_LOGIN} element={<Login />} />
            <Route path={RouteConstants.ROUTE_SIGNUP} element={<SignUp />} />
            <Route path={RouteConstants.ROUTE_VERIFY} element={<VerifyOTP />} />
            <Route
              path={RouteConstants.ROUTE_CONFIRM_RESET_PASSWORD}
              element={<ConfirmResetPassword />}
            />
            <Route
              path={RouteConstants.ROUTE_ALREADY_ACCOUNT}
              element={<AlreadyAnAccount />}
            />

            <Route
              path={RouteConstants.ROUTE_RESET_PASSWORD}
              element={<ResetPassword />}
            />

            <Route
              path={RouteConstants.ROUTE_FORCE_CHANGE_PASSWORD}
              element={<ForceChangePassword />}
            />

            <Route element={<PublicPagesLayout />}>
              <Route
                path={RouteConstants.ROUTE_LEAD_ASSESSMENT_FORM}
                element={<LeadAssessmentForm />}
              />
              <Route
                path={RouteConstants.ROUTE_LEAD_ASSESSMENT_DETAILS}
                element={<LeadAssessmentReportPage />}
              />
            </Route>

            <Route
              path={"*"}
              element={<Navigate to={RouteConstants.ROUTE_LOGIN} />}
            />
          </SentryRoutes>
        </>
      )}
    </Suspense>
  );
};

import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "authenticationData",
  initialState: {
    idToken: localStorage.getItem("idToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    userData: JSON.parse(localStorage.getItem("userData") || "{}"),
    userMode: "",
    forceUserSession: undefined,
  },
  reducers: {
    setTokens: (state, action) => {
      localStorage.setItem("idToken", action.payload.idToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);

      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setUserData: (state, action) => {
      localStorage.setItem("userData", JSON.stringify(action.payload));
      state.userData = action.payload;
    },
    setUserMode: (state, action) => {
      sessionStorage.setItem("userMode", action.payload);
      state.userMode = action.payload;
    },
    setForceUserSession: (state, action) => {
      state.forceUserSession = action.payload;
    },
  },
});

export const { setTokens, setUserData, setUserMode, setForceUserSession } =
  authSlice.actions;

export default authSlice.reducer;

export const BatchUploadHeaderText = () => {
  return (
    <div className="cursor-default">
      <p>Validate or Enrich multiple records using Trestle’s Batch Upload.</p>
      <p>Steps:</p>
      <ol type="1" className="list-decimal ml-[30px] text-start mt-[5px]">
        <li>Select the Product</li>
        <li>
          Upload the CSV file following the templates
          {""}
          <span
            className="!underline !text-[#0494B4] !cursor-pointer ml-[5px] font-bold"
            onClick={() =>
              window.open(
                "https://trestleiq.com/knowledge-base/batch-upload-instructions/"
              )
            }
          >
            provided.
          </span>
        </li>
        <li>
          The results will be emailed to this account email address within an
          hour.
        </li>
      </ol>
    </div>
  );
};

export const LeadAssessmentHeaderText = () => {
  return (
    <div className="cursor-default">
      <p>
        Powered by our advanced Real Contact API, this assessment summarizes
        your contact data into aggregate statistics to understand the health of
        your contact or lead data and compares how your data is doing compared
        to your peers.
      </p>
      <ol type="1" className="list-decimal ml-[30px] text-start mt-[5px]">
        <li>Provide your email</li>
        <li>Upload your CSV file with both names and phone numbers.</li>
        <li>
          Receive your free real contact data assessment in your email within a
          few minutes. A sample assessment similar to what you’ll receive can be
          viewed
          <span
            className="!underline !text-[#0494B4] !cursor-pointer ml-[5px] font-bold"
            onClick={() => window.open("https://trestleiq.com/")}
          >
            here
          </span>
          .
        </li>
      </ol>
    </div>
  );
};

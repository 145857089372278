import { AppBar, Box, Stack } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import TrestleLogo from "../../../assets/trestle_logo.svg";
import { Text14pxBold } from "../CommonComponents";
import TrestleButton from "../TrestleButton";
import { RouteConstants } from "../../../constants/RouteConstants";

const PublicPagesLayout = () => {
  const navigate = useNavigate();
  return (
    <Stack height="100vh">
      <AppBar
        position="static"
        style={{
          background: "#163545",
          fontFamily: "inherit",
          position: "relative",
          zIndex: "111111",
          boxShadow: "none",
        }}
        className="app-bar"
      >
        <div
          style={{
            padding: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="max-w-[315px] w-full">
            <img
              src={TrestleLogo}
              alt="trestle logo"
              className="h-[24px] cursor-pointer"
            />
          </div>
          <div className="flex items-center justify-center gap-[8px]">
            <TrestleButton
              onClick={() => navigate(RouteConstants.ROUTE_LOGIN)}
              label="Log In"
              type="transparent"
              className="!h-[33px] !p-0 !min-w-[83px] !max-w-[83px]"
            />
            <TrestleButton
              onClick={() => navigate(RouteConstants.ROUTE_SIGNUP)}
              label="Sign Up"
              type="primary"
              className="!h-[33px] !p-0 !min-w-[83px] !max-w-[83px]"
            />
          </div>
        </div>
      </AppBar>
      <Outlet />
      <div></div>
      <div
        style={{
          backgroundColor: "#F5F5F5",
          padding: "20px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          width: "100%",
          zIndex: 1000,
          marginTop: "auto",
          height: "92px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <img
            src="../../trestle_icon.svg"
            alt="Logo"
            style={{
              width: "24px",
              height: "24px",
              marginRight: "10px",
            }}
          />

          <a
            href="https://trestleiq.com/terms-of-service/"
            target="_blank" // Open in new tab
            rel="noopener noreferrer" // Security measure
            style={{
              margin: "0 15px",
              color: "#0494B4",
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "120%",
              textAlign: "center",
              letterSpacing: "-0.01em",
            }}
          >
            Terms of Service
          </a>

          <a
            href="https://trestleiq.com/privacy-policy/"
            target="_blank" // Open in new tab
            rel="noopener noreferrer" // Security measure
            style={{
              margin: "0 15px",
              color: "#0494B4",
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "120%",
              textAlign: "center",
              letterSpacing: "-0.01em",
            }}
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </Stack>
  );
};

export default PublicPagesLayout;

import { useEffect, useState } from "react";
import { TabTextComponent } from "../../Shared/ComponentDrawer/componentDrawer";
import { ComponentLabelHeader } from "../../Shared/CommonComponents";
import UsagePerCustomerPerProduct from "./UsagePerCustomerPerProduct";
import UsagePerAPIPerProduct from "./UsagePerAPIPerProducts";
import MasterReport from "./MasterReport";
import CustomerUsage from "./CustomerUsage";
import { useAppSelector } from "../../../app/hooks";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import CustomerUsageV2 from "./CustomrUsageV2";
import ErrorCountViewer from "./ErrorCountViewer";
import MasterReportV2 from "./MasterReportV2";
import SelfServeUsage from "./SelfServeUsage";

interface YourUsageProps {
  userId?: string | number;
}

function AdminUsage({ userId = 0 }: YourUsageProps) {
  const [selectedTab, setSelectedTab] = useState<string>("Customer Usage");

  const TabOptions = [
    "Customer Usage",
    // "Customer Usage V2",
    // "Error Counts",
    // "Usage Per Product For Customers",
    // "Usage per API Key Per Product",
    "Customer Usage - Errors",
    "Master Report",
    // "Master Report V2",
    "Self-Serve Usage",
  ];

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    handleMixPanel(MixPanelEvents.VISIT_ADMIN_USAGE_PAGE, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  }, []);

  return (
    <>
      {!userId && (
        <div>
          <div className="flex pl-[50px] mb-[30px]">
            <ComponentLabelHeader className="flex-1">
              Product Usage
            </ComponentLabelHeader>
          </div>
        </div>
      )}
      <div className="flex pl-[50px]">
        {TabOptions.map((tab: string) => {
          return (
            <TabTextComponent
              selected={selectedTab === tab}
              key={tab}
              onClick={() => {
                setSelectedTab(tab);
              }}
            >
              {tab}
            </TabTextComponent>
          );
        })}
      </div>
      {/* version 2 */}
      {/* {selectedTab === "Customer Usage" && <CustomerUsage />} */}
      {selectedTab === "Customer Usage" && <CustomerUsageV2 />}
      {selectedTab === "Customer Usage - Errors" && <ErrorCountViewer />}
      {/* {selectedTab === "Usage Per Product For Customers" && (
        <UsagePerCustomerPerProduct />
      )}
      {selectedTab === "Usage per API Key Per Product" && (
        <UsagePerAPIPerProduct />
      )} */}
      {/* version 2 */}
      {/* {selectedTab === "Master Report" && <MasterReport />} */}
      {selectedTab === "Master Report" && <MasterReportV2 />}
      {selectedTab === "Self-Serve Usage" && <SelfServeUsage />}
    </>
  );
}

export default AdminUsage;

import { Box, IconButton, Stack } from "@mui/material";
import React, { DragEvent, useRef, useState } from "react";
import { Text16px } from "./CommonComponents";
import { ActionButtonContainer } from "./PaymentWallet/WalletComponets/Styles";
import { BatchUploadIcon } from "../../assets/svgs";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { AppConstants } from "../../constants/AppConstants";
import { ConstantTexts } from "../../constants/ConstantTexts";

interface DragAndDropFileUploaderProps {
  setIsOver: any;
  setFiles: any;
}

const DragAndDropFileUploaderComp = ({
  setIsOver,
  setFiles,
}: DragAndDropFileUploaderProps) => {
  const dispatch = useAppDispatch();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);

    if (droppedFiles?.length > 1) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.FILE_UPLOAD_LIMIT,
        })
      );
      return;
    }

    const file = droppedFiles[0];
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.FILE_TYPE_ERROR, // Add a constant for file type error message
        })
      );
      return;
    }

    setFiles(droppedFiles);
  };

  const handleChange = (event: any) => {
    const file = event.target.files;
    if (file.length <= 1) {
      setFiles(file);
    } else
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: ConstantTexts.FILE_UPLOAD_LIMIT,
        })
      );
    return;
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={{ border: "2px dashed #5555554D", py: "24px", background: "#F5F5F5" }}
    >
      <Stack
        sx={{ justifyContent: "center", alignItems: "center" }}
        spacing={"8px"}
      >
        <IconButton
          sx={{
            color: "#FFFFFF",
            background: "#D5D5D5",
            borderRadius: "50%",
            width: "32px",
            height: "32px",
            marginTop: "0px",
            cursor: "default",
            "&:hover": {
              background: "#D5D5D5",
            },
          }}
        >
          {BatchUploadIcon}
        </IconButton>
        <Text16px style={{ cursor: "default" }}>
          Drag and drop a file here
        </Text16px>{" "}
        <Text16px style={{ cursor: "default" }}>- or -</Text16px>
        <Box>
          <input
            type="file"
            ref={hiddenFileInput}
            multiple={false}
            accept="text/csv"
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <ActionButtonContainer
            onClick={() => {
              hiddenFileInput?.current?.click();
            }}
          >
            Browse Files
          </ActionButtonContainer>
        </Box>
      </Stack>
    </Box>
  );
};

export default DragAndDropFileUploaderComp;

import React, { useState } from "react";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";
import { BackwardArrow } from "../../assets/svgs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Box, Grid } from "@mui/material";
import InputTextField from "../Shared/InputTextField";
import { AppConstants } from "../../constants/AppConstants";
import {
  formatName,
  formatPhone,
  validatePhone,
  validationEmail,
} from "../../utils/validations";
import { Text16px } from "../Shared/CommonComponents";
import TrestleButton from "../Shared/TrestleButton";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { addNewUserToTeam } from "../../http/authentication-service";
import { HTTP_STATUS } from "../../http/constants/http.status";
import { setToast } from "../../app/toastReducer";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { handleMixPanel } from "../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../constants/MixPanelEvents";

interface IAddNewUserComponentProps {
  setAddNewUserClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  closeDrawer: Function;
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  // phone: string;
}

const AddNewUserComponent = ({
  setAddNewUserClicked,
  setLoading,
  closeDrawer,
}: IAddNewUserComponentProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const walletData = useAppSelector((state) => state.walletReducer.walletData);
  const [formData, setFormData] = React.useState<IFormData>({
    firstName: "",
    lastName: "",
    email: "",
    // phone: "",
  });

  const [formError, setFormError] = useState<IFormData>({
    firstName: "",
    lastName: "",
    email: "",
    // phone: "",
  });

  const onInputValueChange = (event: any) => {
    let { name, value } = event.target;
    if (value.trim()) {
      // if (name === "phone" && value?.trim()) {
      //   let t = formatPhone(value);
      //   value = t || formData.phone;
      // }
      if (name === "team" || name === "company") {
        value = formatName(value);
      }
      if (name === "firstName" || name === "lastName") {
        value = formatName(value);
      }
    }
    setFormData((initialValues: IFormData) => {
      return {
        ...initialValues,
        [name]: value,
      };
    });
  };

  const inviteHandleValidation = () => {
    const {
      firstName = "",
      lastName = "",
      email = "",
      // phone = "",
    } = formData || {};

    let tempError = {
      firstName: firstName.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      lastName: lastName.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      email: validationEmail(email),
      // phone: validatePhone(phone, "SIGNUP"),
    };
    let isError: boolean = true;

    if (
      tempError.email ||
      // tempError.phone ||
      tempError.firstName ||
      tempError.lastName
    ) {
      isError = false;
    }

    setFormError({ ...tempError });
    return isError;
  };

  const inviteNewUser = async () => {
    if (inviteHandleValidation()) {
      setLoading(true);
      const payload = formData;
      const response = await addNewUserToTeam(payload);
      const { status = 401, data = {} } = response || {};
      if (status === HTTP_STATUS.HTTP_OK) {
        setLoading(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: ConstantTexts.USER_ADDED_TO_TEAM_SUCCESS.replace(
              "{email}",
              payload.email
            ),
            heading: "Invitation sent!",
            autoCloseTime: 5000,
          })
        );
        handleMixPanel(MixPanelEvents.INVITE_SENT_SUCCESSFULLY, {
          email: payload.email,
        });
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          // phone: "",
        });
      } else {
        const { error = "", details = "" } = data || {};
        if (details === "Email is Invalid") {
          setFormError({ ...formError, email: AppConstants.EMAIL_ERROR_MSG });
        } else {
          dispatch(
            setToast({
              open: true,
              severity: "error",
              msg: details,
              autoCloseTime: 5000,
            })
          );
          if (details === "User with same email already exists") {
            dispatch(
              setToast({
                open: true,
                severity: "error",
                msg: details,
                autoCloseTime: 5000,
              })
            );
          }
        }

        if (error === "Email is Invalid") {
          handleMixPanel(MixPanelEvents.USER_ENTERED_INVALID_EMAIL, {
            email: payload.email,
            error: error,
          });
        }
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setAddNewUserClicked(false);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      // phone: "",
    });
    setFormError({
      firstName: "",
      lastName: "",
      email: "",
      // phone: "",
    });
  };

  return (
    <Box className={`min-h-full p-[50px] bg-white`}>
      <ActionButtonContainer
        className="w-fit"
        onClick={() => {
          setAddNewUserClicked(false);
        }}
      >
        {BackwardArrow} Back
      </ActionButtonContainer>
      {walletData.walletDisplayType === "UNLIMITED" ? (
        <Box>
          <Grid container spacing={4} className="py-[48px]">
            <Grid item md={6}>
              <InputTextField
                name={"firstName"}
                label={"First Name"}
                value={formData}
                error={formError}
                type={"text"}
                disabled={false}
                onChange={onInputValueChange}
                required={true}
                maxLength={AppConstants.MAX_NAME_LENGTH}
                height="50px"
              />
            </Grid>
            <Grid item md={6}>
              <InputTextField
                name={"lastName"}
                label={"Last Name"}
                value={formData}
                error={formError}
                type={"text"}
                disabled={false}
                onChange={onInputValueChange}
                required={true}
                maxLength={AppConstants.MAX_NAME_LENGTH}
                height="50px"
              />
            </Grid>
            <Grid item md={12}>
              <InputTextField
                name={"email"}
                label={"Email"}
                value={formData}
                error={formError}
                type={"text"}
                disabled={false}
                onChange={onInputValueChange}
                required={true}
                maxLength={AppConstants.MAX_NAME_LENGTH}
                height="50px"
              />
            </Grid>
            {/* <Grid item md={6}>
              <InputTextField
                name={"phone"}
                label={"Phone number"}
                value={formData}
                error={formError}
                type={"text"}
                disabled={false}
                onChange={onInputValueChange}
                required={true}
                maxLength={AppConstants.MAX_NAME_LENGTH}
                height="50px"
              />
            </Grid> */}
          </Grid>
          <Box className="flex gap-[20px]">
            <TrestleButton
              onClick={inviteNewUser}
              label="Send Invitation"
              type="primary"
              className="max-w-fit !h-[48px] !py-[0] !px-[60px]"
            />
            <TrestleButton
              onClick={handleCancel}
              label="Cancel"
              type="new_contact"
              className="max-w-fit !h-[48px] !py-[0] !px-[60px]"
            />
          </Box>
        </Box>
      ) : (
        <Box className="flex flex-col gap-[30px] py-[40px]">
          <Text16px>
            This feature is only available for customers with an Enterprise
            agreement with Trestle.
          </Text16px>
          <Box className="flex gap-[20px]">
            <TrestleButton
              onClick={() => (
                navigate(RouteConstants.ROUTE_PRICING_PAGE), closeDrawer()
              )}
              label="Upgrade to Enterprise"
              type="primary"
              className="max-w-fit !h-[48px] !py-[0] !px-[60px]"
            />
            <TrestleButton
              onClick={handleCancel}
              label="Cancel"
              type="new_contact"
              className="max-w-fit !h-[48px] !py-[0] !px-[60px]"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddNewUserComponent;

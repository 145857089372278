import { Box } from "@mui/material";
import { Text16px, WalletBalanceText } from "../../Shared/CommonComponents";
import { BatchUploadUpgradeIcon } from "../../../assets/svgs";
import TrestleButton from "../../Shared/TrestleButton";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { useAppSelector } from "../../../app/hooks";
import { RouteConstants } from "../../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";

const BatchUploadUpgrade = () => {
  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        minHeight: "100%",
        background: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <Box>{BatchUploadUpgradeIcon}</Box>
      <WalletBalanceText className="text-center">
        Batch Upload Is Only Available for <br />
        Self-serve or Enterprise Users
      </WalletBalanceText>
      <Text16px>Please Upgrade to Use</Text16px>
      <Box className="flex flex-col gap-[10px] ">
        <TrestleButton
          onClick={() => {
            navigate(RouteConstants.ROUTE_PRICING_PAGE);
          }}
          label="Upgrade to Self-serve Wallet"
          type="new_primary"
          className="!h-[60px] px-[60px]"
        />
        <TrestleButton
          onClick={() => {
            window.open("https://trestleiq.com/contact/", "_blank");
            handleMixPanel(MixPanelEvents.WALLET_CONTACT_SALES, {
              userEmail: userData?.email,
            });
          }}
          label="Contact Sales for Enterprise"
          type="new_contact"
          className="!h-[60px]  px-[60px]"
        />
      </Box>
    </Box>
  );
};

export default BatchUploadUpgrade;

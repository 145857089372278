import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  MailIcon,
  LinkIcon,
  KeyIcon,
  PieChartIcon,
  StackIcon,
  BookIcon,
  LockIcon,
  UserIcon,
  NeedHelpIcon,
  BatchUploadIcon,
} from "../../../assets/svgs";
import { RouteConstants } from "../../../constants/RouteConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { AppConstants } from "../../../constants/AppConstants";
import { useAppSelector } from "../../../app/hooks";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";

const drawerWidth = 315;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: 90,
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
    width: 90,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const NewDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function TrestleAppDrawerNew(props: any) {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const compoent_div = document.getElementById("comp-render");
    if (compoent_div) {
      compoent_div?.scrollTo(0, 0);
    }
  }, [location]);

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const clickDocLogMixpanel = () => {
    handleMixPanel(MixPanelEvents.CLICK_DOCUMENTATION, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  };

  const clickNeedHelpLogMixpanel = () => {
    handleMixPanel(MixPanelEvents.CLICK_NEED_HELP_ICON, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  };

  const AdminRoutes = [
    {
      text: "Product Access",
      action: () => navigate(RouteConstants.ROUTE_ADMIN_PRODUCT_ACCESS),
      path: RouteConstants.ROUTE_ADMIN_PRODUCT_ACCESS,
      icon: LockIcon,
    },
    {
      text: "All Users",
      action: () => navigate(RouteConstants.ROUTE_ADMIN_ALL_USERS),
      path: RouteConstants.ROUTE_ADMIN_ALL_USERS,
      icon: UserIcon,
    },
    {
      text: "Batch Uploads",
      action: () => navigate(RouteConstants.ROUTE_ADMIN_BATCH_UPLOAD),
      path: RouteConstants.ROUTE_ADMIN_BATCH_UPLOAD,
      icon: BatchUploadIcon,
    },
    {
      text: "Usage",
      action: () => navigate(RouteConstants.ROUTE_ADMIN_USAGES),
      path: RouteConstants.ROUTE_ADMIN_USAGES,
      icon: PieChartIcon,
    },
    // {
    //   text: "Teams",
    //   action: () => navigate(RouteConstants.ROUTE_ADMIN_TEAMS),
    //   path: RouteConstants.ROUTE_ADMIN_TEAMS,
    //   icon: TeamsIcon,
    // },
    {
      text: "API Docs",
      action: () => {
        clickDocLogMixpanel();
        window.open(AppConstants.TRESTLE_DOCUMENT_URL);
      },
      isLink: true,
      icon: BookIcon,
    },
  ];

  const userRoutes = [
    {
      text: "Your API Keys",
      action: () => navigate(RouteConstants.ROUTE_YOUR_API_KEY),
      path: RouteConstants.ROUTE_YOUR_API_KEY,
      icon: KeyIcon,
    },
    {
      text: "Batch Upload",
      action: () => navigate(RouteConstants.BATCH_UPLOAD),
      path: RouteConstants.BATCH_UPLOAD,
      icon: BatchUploadIcon,
    },
    {
      text: "Your Usage",
      action: () => navigate(RouteConstants.ROUTE_YOUR_USAGE),
      path: RouteConstants.ROUTE_YOUR_USAGE,
      icon: PieChartIcon,
    },
    // {
    //   text: "Your Usage",
    //   action: () => navigate(RouteConstants.ROUTE_YOUR_USAGE_V2),
    //   path: RouteConstants.ROUTE_YOUR_USAGE_V2,
    //   icon: PieChartIcon,
    // },
    {
      text: "Pricing Plans",
      action: () => {
        navigate(RouteConstants.ROUTE_PRICING_PAGE);
      },
      path: RouteConstants.ROUTE_PRICING_PAGE,
      icon: StackIcon,
    },

    {
      text: "API Docs",
      action: () => {
        clickDocLogMixpanel();
        window.open(AppConstants.TRESTLE_DOCUMENT_URL);
      },
      isLink: true,
      icon: BookIcon,
    },
  ];

  const ListElement = (
    index: number,
    text: string,
    action: any = () => {},
    isLink: boolean = false,
    icon: any = MailIcon,
    isSelected: boolean = false
  ) => {
    return (
      <ListItem
        key={`Route-${index}`}
        disablePadding
        sx={{
          display: "flex",
          width: "100%",
          minHeight: 48,
          justifyContent: "center",
          background: isSelected ? "#1D4E5A" : "rgb(68 218 211 / 6%)",
          color: isSelected ? "#A2F0EC" : "#FFFFFF",
          alignItems: "center",
          flexDirection: "column",
          py: "20px",
          gap: "10px",
          cursor: "pointer",
        }}
        className="mb-[1px]"
        onClick={() => {
          action();
        }}
      >
        {icon}
        <ListItemText
          primary={
            <div className="flex leading-4">
              <span className="listItemText">{text}</span>
            </div>
          }
          className={` !text-[${isSelected ? "#A2F0EC" : "#FFFFFF"}]`}
          style={{ width: "100%" }}
        />
      </ListItem>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <NewDrawer
        variant="permanent"
        className={`!bg-[#163545] ${"trestle-drawer-fit"}`}
      >
        <DrawerHeader className="app-bar"></DrawerHeader>
        <List className="!bg-[#163545] h-full w-full">
          {props.isAdmin
            ? AdminRoutes.map((ele: any, index: number) =>
                ListElement(
                  index,
                  ele.text,
                  ele.action,
                  ele?.isLink,
                  ele?.icon,
                  window.location.pathname.includes(ele.path)
                )
              )
            : userRoutes.map((ele: any, index: number) => {
                return ListElement(
                  index,
                  ele.text,
                  ele.action,
                  ele?.isLink,
                  ele?.icon,
                  window.location.pathname.includes(ele.path)
                );
              })}
          {!props.isAdmin && (
            <div className="w-full">
              {ListElement(
                99,
                "Need Help?",
                () => {
                  window.open("https://trestleiq.com/contact/", "_blank");
                },
                true,
                NeedHelpIcon,
                false
              )}
            </div>
          )}
        </List>
      </NewDrawer>
      <div className="w-full !overflow-hidden">{props.children}</div>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import {
  ComponentLabelHeader,
  Text16pxMedium,
} from "../../Shared/CommonComponents";
import LoaderComponent from "../../Shared/LoaderComponent";
import {
  downloadBatch,
  getApiKeysAndProducts,
  getBatchFiles,
} from "./BatchUploadService";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import moment from "moment";
import { getUTCDate } from "../../../utils/helpers";
import TrestleButton from "../../Shared/TrestleButton";
import BatchUploadPopUp from "./BatchUploadPopUp";
import { Box } from "@mui/material";
import BatchUploadUpgrade from "./BatchUploadUpgrade";
import { useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { BatchUploadHeaderText } from "../../../constants/HtmlConstText";
import NoBatchDataComponent from "./NoBatchData";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { ActionButtonContainer } from "../../Shared/PaymentWallet/WalletComponets/Styles";

export interface IBatchFilesResponse {
  inputFile: string;
  createdAt: Date;
  productName: string;
  apiKey: string;
  status: string;
  outputFile: string;
  completedAt: Date;
}

const BatchUpload = () => {
  const walletData = useAppSelector((state) => state.walletReducer?.walletData);

  const [loading, setLoading] = useState<boolean>(false);

  const [batches, setBatches] = useState<IBatchFilesResponse[]>([]);

  const [apiKeysProducts, setApiKeysProducts] = useState<{}>({});

  const [pageNumber, setPageNumber] = useState(0);

  const [totalRecords, setTotalRecords] = useState(0);

  const [noOfRecords, setNoOfRecords] = useState(20);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    callGetBatchAPI();
    handleMixPanel(MixPanelEvents.VISIT_USER_BATCH_PAGE, {
      email: userData.email,
    });
  }, []);

  useEffect(() => {
    callGetBatchAPI();
  }, [pageNumber, noOfRecords]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false); // Close the popup
      }
    };

    document.addEventListener("keyup", handleEscape);

    return () => {
      document.removeEventListener("keyup", handleEscape);
    };
  }, []);

  const downloadFile = async (uuid: string, fileName: string) => {
    try {
      setLoading(true);
      const response: any = await downloadBatch(uuid);
      setLoading(false);
      if(response.status!== HTTP_STATUS.HTTP_OK)
      {
        const { details } = response.data;
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: details,
          })
        );
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const callGetBatchAPI = async () => {
    let reqData: any = { pageNo: pageNumber, pageSize: noOfRecords };

    setLoading(true);
    const response = await getBatchFiles(reqData);
    const apiKeysResponse = await getApiKeysAndProducts();
    setLoading(false);

    const { status = 401, data = {} } = response || {};

    if (apiKeysResponse.status === HTTP_STATUS.HTTP_OK) {
      setApiKeysProducts(apiKeysResponse.data.data);
    }

    if (status === HTTP_STATUS.HTTP_OK) {
      setBatches(data.data.batchExecutions);
      setTotalRecords(data.data.totalRecords);
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Batch Id",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return "Batch-" + ele.id;
      },
      sortable: true,
    },
    {
      id: "inputFile",
      numeric: false,
      disablePadding: false,
      label: "Input File Name",
      width: "20%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date Uploaded",
      width: "15%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {moment(getUTCDate(ele.createdAt || "")).format(
              "MM/DD/YYYY HH:mm:ss"
            )}
          </span>
        );
      },
      sortable: false,
    },
    {
      id: "productName",
      numeric: false,
      disablePadding: false,
      label: "Product Name",
      width: "15%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: false,
    },
    {
      id: "completedAt",
      numeric: false,
      disablePadding: false,
      label: "Completion Date",
      width: "15%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {ele.completedAt
              ? moment(getUTCDate(ele.completedAt || "")).format(
                  "MM/DD/YYYY HH:mm:ss"
                )
              : "-"}
          </span>
        );
      },
      sortable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "outputFile",
      numeric: false,
      disablePadding: false,
      label: "Output File",
      width: "20%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {ele.outputFile && ele.uuid ? (
              <ActionButtonContainer
                u={"underline"}
                onClick={() => downloadFile(ele.uuid, ele.outputFile)}
              >
                {ele.outputFile}
              </ActionButtonContainer>
            ) : (
              "-"
            )}
          </span>
        );
      },
      sortable: true,
    },
  ];

  return (
    <>
      <LoaderComponent loader={loading} />

      <BatchUploadPopUp
        open={isOpen}
        apiKeysAndProducts={apiKeysProducts}
        setIsLoading={setLoading}
        callGetBatchAPI={callGetBatchAPI}
        setOpen={setIsOpen}
      />

      <div className="pr-[100px]">
        <div className="flex flex-col gap-[12px] pl-[50px] mb-[40px]">
          <Box>
            <ComponentLabelHeader className="flex-1">
              Batch Upload
            </ComponentLabelHeader>
            <div className="flex gap-[8px] justify-between">
              <Text16pxMedium className="!leading-[1.5]">
                <BatchUploadHeaderText />
              </Text16pxMedium>
              <div className="uploadButton ">
                {!walletData?.walletDisplayType?.includes("TRIAL") && (
                  <TrestleButton
                    onClick={() => {
                      setIsOpen(true);
                      handleMixPanel(MixPanelEvents.CLICKED_ON_BATCH_UPLOAD, {
                        email: userData.email,
                      });
                    }}
                    label="Batch Upload"
                    type="primary"
                    className="!h-[40px] !leading-[40px] !p-0 !min-w-[150px] !max-w-[150px] mt-auto ml-auto"
                  />
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>

      <div className="pr-[50px] bg-white">
        <div className="pl-[50px] h-full">
          <div className="flex py-[30px] justify-between">
            {walletData?.walletDisplayType?.includes("TRIAL") ? (
              <BatchUploadUpgrade />
            ) : (
              <TrestleTable
                isPaginated={true}
                headCells={headCells}
                rows={batches}
                setPageNumber={setPageNumber}
                totalNoOfRecords={totalRecords}
                setNoOfRecords={setNoOfRecords}
                noDataComponent={
                  <NoBatchDataComponent
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchUpload;

import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { TablePagination } from "@mui/material";
import * as _ from "lodash";
import NoDataFound from "../NoDataFound";

export interface TableHeaderProps {
  id: any;
  numeric: boolean;
  disablePadding: boolean;
  label: any;
  width: string;
  isCustomElements: boolean;
  customElements: any;
  sortable: boolean;
  className?: string;
  headClassName?: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => void;
  order: Order;
  orderBy: any;
  rowCount: number;
  headCells: readonly TableHeaderProps[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: TableHeaderProps, index) => (
          <TableCell
            key={`${headCell.id} ${index}`}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
            className={`!text-[#5E6E78] !text-sm !font-normal font-DMSans ${headCell?.headClassName} !max-w-[80px] overflow-hidden`}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className="font-DMSans"
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TrestleTableProps {
  isPaginated?: boolean;
  headCells: readonly TableHeaderProps[];
  rows: any[];
  setPageNumber?: Function;
  setNoOfRecords?: Function;
  totalNoOfRecords?: number;
  hoverEffect?: boolean;
  clientSort?: boolean;
  defaultOrder?: any;
  childRow?: any;
  noDataComponent?: React.ReactElement;
}

export default function TrestleTable({
  isPaginated = false,
  headCells,
  rows,
  setPageNumber,
  setNoOfRecords,
  totalNoOfRecords,
  hoverEffect = true,
  clientSort = false,
  defaultOrder = null,
  childRow = null,
  noDataComponent,
}: TrestleTableProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [data, setData] = React.useState<any[]>([]);
  const [dataR, setDataR] = React.useState<any[]>([]);
  const [dataB, setDataB] = React.useState<any[]>([]);

  React.useEffect(() => {
    setData(rows);
    setDataB(rows);
    setTotalRecords(totalNoOfRecords ? totalNoOfRecords : rows.length);
  }, [rows]);

  React.useEffect(() => {
    setDataR(dataB);
  }, [dataB]);

  React.useEffect(() => {
    if (setPageNumber != null && setNoOfRecords != null) {
      setPageNumber(page);
      setNoOfRecords(rowsPerPage);
    }
  }, [rowsPerPage, page]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    let row_per_page = isPaginated ? rowsPerPage : rows.length;
    let temp = stableSort(data, getComparator(order, orderBy)).slice(
      0 * row_per_page,
      0 * row_per_page + row_per_page
    );

    if (clientSort) {
      setDataB(temp);
      setDataR(clientSort ? [] : rows);
    } else {
      setDataR(temp);
    }
  }, [order, orderBy, page, rowsPerPage, data]);

  React.useEffect(() => {
    if (defaultOrder) {
      setOrderBy(defaultOrder?.orderBy);
      setOrder(defaultOrder?.order);
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", boxShadow: "none", fontFamily: "DM Sans" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {dataR.map((row: any, rowIndex) => {
                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                      sx={{ cursor: "pointer", height: "60px" }}
                      className={`!cursor-auto ${
                        !hoverEffect && "!bg-inherit"
                      }`}
                    >
                      {headCells.map((ele: any, cellIndex) => {
                        return (
                          <TableCell
                            key={`cell ${rowIndex} ${cellIndex}`}
                            width={ele.width}
                            className={`${
                              ele?.className || ""
                            } font-DMSans !max-w-[80px] overflow-hidden text-ellipsis whitespace-nowrap`}
                          >
                            {ele.isCustomElements ? (
                              ele.customElements(row)
                            ) : (
                              <div style={{ cursor: "default" }}>
                                {row[ele.id]}
                              </div>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      {childRow && (
                        <TableCell
                          key={`childCell ${rowIndex}`}
                          sx={{ padding: "0px" }}
                          colSpan={4}
                        >
                          {childRow(row)}
                        </TableCell>
                      )}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
          {_.isEmpty(dataR) && (
            <>
              {noDataComponent ? (
                <>{noDataComponent}</>
              ) : (
                <Box className="flex items-center justify-center h-[calc(100vh_-_600px)]">
                  <NoDataFound />
                </Box>
              )}
            </>
          )}
        </TableContainer>
        {isPaginated && (
          <TablePagination
            rowsPerPageOptions={[20, 40, 60, 80, 100]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

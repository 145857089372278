import React, { useEffect } from "react";
import { Popover, Box, Typography, Stack } from "@mui/material";
import { ProfileMenuText } from "../CommonComponents";
import {
  DownArrow,
  WalletCloseIcon,
  WalletOpenIcon,
  WalletWarningCloseIcon,
  WalletWarningOpenIcon,
} from "../../../assets/svgs";
import TrialPlanComponent from "./WalletComponets/TrialPlanComponent";
import SelfServePlanComponent from "./WalletComponets/SelfServePlanComponent";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";

interface PopoverProps {
  anchorElPayment: Element | null;
  handleOpenPaymentMenu: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  setAnchorElPayment: any;
  setIsLoading: any;
}

const PaymentWalletComponent = ({
  anchorElPayment,
  handleOpenPaymentMenu,
  setAnchorElPayment,
  setIsLoading,
}: PopoverProps) => {
  const walletData = useAppSelector((state) => state.walletReducer.walletData);

  const handleClosePaymentMenu = () => {
    setAnchorElPayment(null);
  };

  const getPopoverComponent = () => {
    switch (walletData.walletDisplayType) {
      case "TRIAL":
        return (
          <TrialPlanComponent
            walletData={walletData}
            setAnchorEl={setAnchorElPayment}
          />
        );
      case "SELF_SERVE":
        return (
          <SelfServePlanComponent
            walletData={walletData}
            setAnchorEl={setAnchorElPayment}
            // getWalletData={GetWalletDataAPICall}
          />
        );
      case "TRIAL_EXPIRED":
        return (
          <TrialPlanComponent
            walletData={walletData}
            setAnchorEl={setAnchorElPayment}
          />
        );
      default:
        return <></>;
    }
  };

  const getDisplayText = () => {
    switch (walletData.walletDisplayType) {
      case "TRIAL":
        return "Trial";
      case "SELF_SERVE":
        if (parseFloat(walletData.balance as any) < 0) {
          return `-$${
            parseFloat(walletData.balance as any)
              ? parseFloat(-walletData?.balance as any).toFixed(2)
              : 0
          }`;
        }
        return `$${
          parseFloat(walletData.balance as any)
            ? parseFloat(walletData.balance as any).toFixed(2)
            : 0
        }`;
      case "TRIAL_EXPIRED":
        return "Trial Ended";
      case "UNLIMITED":
        return "Unlimited";
      default:
        return "";
    }
  };

  const showWarningIcon = () => {
    if (
      walletData.walletDisplayType === "TRIAL" ||
      walletData.walletDisplayType === "UNLIMITED"
    ) {
      return false;
    } else if (walletData.balance && parseFloat(walletData.balance) < 10) {
      return true;
    } else if (
      walletData.autoRechargeEnabled === true &&
      walletData.autoRechargeSuccess === false
    ) {
      return true;
    } else if (walletData.walletDisplayType === "TRIAL_EXPIRED") {
      return true;
    } else {
      return false;
    }
  };

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  return (
    <>
      {walletData?.walletDisplayType && walletData.walletDisplayType !== "UNLIMITED" && (
        <>
          <Box
            sx={{
              flexGrow: 0,
              background: anchorElPayment ? "#EAEBEC" : "",
              color: anchorElPayment ? "#163545" : "",
            }}
            className="px-[35px]"
            onClick={(event) => {
              handleMixPanel(MixPanelEvents.WALLET_DROPDOWN_CLICK, {userEmail: userData?.email});
              if (walletData?.walletDisplayType === "UNLIMITED") {
                return;
              }
              handleOpenPaymentMenu(event);
            }}
          >
            <Box className="flex cursor-pointer app-bar">
              <Stack direction={"row"} sx={{ alignItems: "center" }}>
                {showWarningIcon() ? (
                  <Box sx={{ mb: "7px" }}>
                    {anchorElPayment
                      ? WalletWarningOpenIcon
                      : WalletWarningCloseIcon}
                  </Box>
                ) : (
                  <>{anchorElPayment ? WalletOpenIcon : WalletCloseIcon}</>
                )}
                <ProfileMenuText
                  w={"fit-content"}
                  className={`app-bar ${showWarningIcon() ? "!ml-[5px]" : ""}`}
                >
                  {getDisplayText()}
                </ProfileMenuText>
              </Stack>
              {walletData?.walletDisplayType !== "UNLIMITED" && (
                <Box
                  className="my-auto"
                  style={{
                    transform: anchorElPayment
                      ? "rotate(3.142rad)"
                      : "rotate(0)",
                  }}
                >
                  {DownArrow}
                </Box>
              )}
            </Box>
          </Box>
          <Popover
            anchorEl={anchorElPayment}
            open={Boolean(anchorElPayment)}
            onClose={handleClosePaymentMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              boxShadow:
                "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
              "& .MuiPopover-paper ": {
                borderRadius: "0px !important",
              },
            }}
          >
            {getPopoverComponent()}
          </Popover>
        </>
      )}
    </>
  );
};

export default PaymentWalletComponent;
